import React, {useEffect, useState} from "react";
import { Row, Col,Button, Form, Input, Select, Checkbox, notification} from "antd";
import { useSelector} from 'react-redux';
import { createBuyerIdentifyApi} from "../../services/buyerApi";
import {getTaxAdministartions,getDistrictsApi} from "../../services/commonApi"
import {getCorporationsListApi} from "../../services/financeApi";
import {validateVkn} from "../../helpers/validators";

function Identification() {

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [financial, setFinancial] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]);
  const [taxAdministartion, setTaxAdministartion] = useState<any>([]);
  const {provinces} = useSelector((state: any) => state.common);

  const [financialInstitution, setFinancialInstitution] = useState<any>([]); 
  const [value, setValue] = useState<any>([]);
  const [id, setId] = useState<any>([]);
  const [check, setCheck] = useState<any>(false);
  const [checkIntegration, setCheckIntegration] = useState<any>(false);

  const onChange = (checkedValues:any) => {
    setCheck( checkedValues.target.checked)
  };
  
  const onChangeIntegration = (checkedValues:any) => {
    setCheckIntegration( checkedValues.target.checked)
  }; 

  const handleFinancialId = (event:any, key:any) => {
    const number:any = key.slice(0,1)
    if(key === `${number}id`  ){
        id[number] = event.name
        value[number] = 0
    }
    if(key === `${number}limit` ){            
        if(event.value !==''){
            value[number] = event.value
        }
    }
    if(event.value !== undefined  && event.name !== undefined && event.name !== ''){
        
        financialInstitution[number] = {
            financialInstitutionId : id[number],
            limit : value[number]
        }
    }else{
        value.splice(number,1)
    }        

    if(event.type === 'checkbox' && event.checked === false ){
        financialInstitution.splice(number,1) 
        id.splice(number,1) 
        value.splice(number,1) 
    }
    
}

  const onFinish =  async (values:any) => {
   setLoading(true);
    try{
      const financialID = financialInstitution.filter((item:any) => {
        if(item.financialInstitutionId !== null && 
            item.limit !== null && 
            item.financialInstitutionId !== undefined && 
            item.limit !== undefined)
            {
           return item
        } return 
      });
      const provinceName = provinces.filter((item:any) => {
        if(item.provinceId === values.province){
          return item
        }
        return 
      });
      const newData = {
        title : values.title,
        province: provinceName[0]?.name,
        state: values.district,
        taxAdministration: values.taxAdministration,
        taxId: values.taxId,
        address: values.address,
        type: values.type,
        // isIntegrated: check,
        isIntegrated: checkIntegration,
        autoMarkInvoicesAsPaidEnabled: check ,
        // contractDate: values.date.toISOString().replace("Z", ""),
        // buyerContractTerm:values.dateMaturity.toISOString().replace("Z", ""),
        financialInstitutionLimits : financialID
      }
   
      const response = await createBuyerIdentifyApi(newData);
      if(response) {
        notification.success({
          message: `Başarıyla kayıt oluşturulmuştur.`,
        });   
        form.resetFields();
        setCheck(false);
        setCheckIntegration(false);
        } 
        setLoading(false)

    }catch(e:any){
      console.log('e',e)
      setLoading(false);
      if (e.status === 409) {
        notification.error({
          message: `Aynı VKN ile kayıt bulunmaktadır (${values.taxId}) `,
        });
      }
      if (e.status === 400 && e.response.Type.includes('FinancialInstitutionLimitsCannotBeNullException')) {
        notification.error({
          message: `En az bir finans kurumu için limit bilgisi tanımlamalısınız. `,
        });
        
      }
    }  
  };



const getDistrictsTaxId = async (dataId:any) => {
  form.resetFields(['taxAdministration']);
  form.resetFields(['invoiceDistrict']);

  try {
    const response: any = await getDistrictsApi(dataId);
    if (response) {
      setDistrictList(response);
    }
  } catch (error: any) {
    console.log(error);
  }


  try {
    const response: any = await getTaxAdministartions(dataId);
    if (response) {
      setTaxAdministartion(response);
    }
  } catch (error: any) {
    console.log(error);
  }
}


const getCorporationsList =  async () => {
  try {
    const response: any = await getCorporationsListApi();
    if (response) {
      setFinancial(response.financialInstitutions)
    }
  } catch (error: any) {
    console.log(error);
  }
}

  useEffect(() => {
    getCorporationsList();
       // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[])

  return (
    <main>
      <Row className="margin-bottom">
        <Col span={24}>
          <h3 className="page-title"> Alıcı Tanımlama</h3>
        </Col>
      </Row>
      <Row>
      <Col span={24}>
        <Form
          form={form}
          name="user-update"
          className="default-form"
          onFinish={onFinish}
          labelCol={{
          span: 10,
          }}
          wrapperCol={{
            span: 14,
          }}
          layout="vertical"
          initialValues={{
            size: 'large',
          }}
          size={'large'}
          style={{
            width: '100%',
            display:'flex'
          }}
          >
       
          <div className="form-col-box" style={{paddingTop:'43px'}}>

            <Form.Item 
              label="Ünvan:"
              name="title"
              rules={[
                {
                    required: true,
                    message: 'Lütfen ünvan giriniz.',
                },
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item 
              label="Alıcı Türünü Seç:"
              name="type"
              rules={[
                {
                    required: true,
                    message: 'Lütfen alıcı türünü  seçiniz.',
                },
              ]}
              >
              <Select  
                optionFilterProp="children"
                showSearch
                >
                <Select.Option key='1' value='TEDARIKCI_ALICISI' >Tedarikçi Alıcısı</Select.Option>
                <Select.Option key='2' value='CIFTCI_ALICISI' >Çiftçi Alıcısı</Select.Option>
                <Select.Option key='3' value='CIFTCI_VE_TEDARIKCI_ALICISI' >Tedarikçi ve Çiftçi Alıcısı</Select.Option>
              </Select>
            </Form.Item>

            <h4 style={{marginBottom:'30px', marginTop:'30px', textDecoration:'underline'}}>Finans Kurumları</h4>
            <div className="detail-column" style={{border:'none'}}>
              <ul style={{paddingLeft:'0'}}  className="dev-label">                     
                 {
                    financial && financial.map((item:any, index:any) => {
                      return (
                        <li key={index} style={{display:'flex', alignItems:'center'}}>
                            <Form.Item name='checkbox' style={{width:'180px'}}>
                              <Checkbox
                                name={item.id}
                                onChange={(e:any) => handleFinancialId(e.target, `${index}id` )}
                              /> <span>{item.name}</span>
                            </Form.Item>     
                       
                             <Form.Item 
                                name=  {`${index}limit`} 
                                className="dev-input"    
                                style={{width:'180px', paddingLeft:'5px'}}
                              >
                                <Input  name={`${index}limit`}  key= {`${index}id`}  onBlur={ (e) => handleFinancialId(e.target, `${index}limit` )} />
                              </Form.Item>
                        
                          </li>
                               )
                                })
                        }
              </ul> 
            
          </div>
            
            </div>
            <div className="form-col-box">
              <h4 style={{marginBottom:'20px', textDecoration:'underline'}}>Fatura Adresi</h4>
              <Form.Item 
              label="İl:"
              name="province"
              rules={[
                {
                    required: true,
                    message: 'Lütfen il giriniz.',
                },
              ]}
              >
              <Select  
                optionFilterProp="children"
                showSearch
                onChange={(e:any) => getDistrictsTaxId(e)}
                >
                {
                 provinces && provinces.map((item:any, index:any) => {
                    return(
                      <Select.Option key={index} value={item.provinceId}>{item.name}</Select.Option>
                    )
                  })
                }
               
              </Select>
              </Form.Item>
                <Form.Item 
                label="İlçe:"
                name="district"
                rules={[
                  {
                      required: true,
                      message: 'Lütfen ilçe giriniz.',
                  },
                ]}
                >
                <Select  
                  optionFilterProp="children"
                  showSearch
                  >
                  {
                  districtList && districtList.map((item:any, index:any) => {
                      return(
                        <Select.Option key={index} value={item}>{item}</Select.Option>
                      )
                    })
                  }
                
                </Select>
              </Form.Item>
                <Form.Item 
                label="Vergi Dairesi:"
                name="taxAdministration"
                rules={[
                  {
                      required: true,
                      message: 'Lütfen vergi dairesi seçiniz.',
                  },
                ]}
                >
                <Select  
                  optionFilterProp="children"
                  showSearch
                  >
                  {
                  taxAdministartion && taxAdministartion.length > 0 ? taxAdministartion.map((item:any, index:any) => {
                      return(
                        <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                      )
                    }): ''
                  }
                
                </Select>
              </Form.Item>
              <Form.Item 
                label="Vergi Kimlik Numarası:"
                name="taxId"
                rules={[
                  {
                    validator: (_, value) => validateVkn(value)
                  },
                ]}
                >
                <Input/>
              </Form.Item>
              <Form.Item 
                label="Adres"
                name="address"
                rules={[
                  {
                      required: true,
                      message: 'Lütfen adres giriniz.',
                  },
                ]}
              >
                <TextArea
                rows={4} 
                />
              </Form.Item>

              <div>
              <Form.Item name="autoMarkInvoicesAsPaidEnabled" style={{marginBottom:'0'}} >
                  <Checkbox  checked={check}  onChange={onChange}>Otomatik Fatura Ödendi Olarak İşaretle</Checkbox>
                </Form.Item>
                <Form.Item name="isIntegrated" >
                  <Checkbox  checked={checkIntegration}  onChange={onChangeIntegration}>Entegre Alıcısı</Checkbox>
                </Form.Item>
            </div>

              {/* <Form.Item name="autoMarkInvoicesAsPaidEnabled" >
                  <Checkbox  checked={check}  onChange={onChange}>Entegre Alıcısı</Checkbox>
                </Form.Item>
        */}
            <Form.Item style={{marginTop:'25px'}}>
              <Button type="primary"  htmlType="submit" loading={loading}>Alıcı Kaydet</Button>
            </Form.Item>
      
            </div>
        </Form>
        </Col>
      </Row>
     
    </main>
  );
};


export default Identification;
