import React, {useState, useEffect } from "react";
import { Row,  Col, Upload, Form, Spin, notification } from "antd";
import {DownloadOutlined, CloseOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import { useSelector} from 'react-redux';
import { getSuppleirDetailApi, uploadDoc, getDocType, deleteDocs, getDocDownload } from "../../services/supplierApi";
import { useLocation } from "react-router-dom";

function AccountTransactionsDetail() {
  const location = useLocation();
  const discountsId = location.pathname.split('/')[2];
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<any>();
  const [docType, setDocType] = useState<any>();
  const [legalDocs, setLegalDocs] = useState<any>({
    taxBoard: undefined,
    authorizedSignatures: undefined,
    activityCertificate: undefined,
    // serviceAggrement: undefined,
});


const {provinces} = useSelector((state: any) => state.common);
const antIcon = <LoadingOutlined style={{ fontSize: 17, color: 'black', marginRight: 10 }} spin />;


  const getSuppleirDetail  = async () => {  
    try {
      setLoading(true);
      const response: any = await getSuppleirDetailApi(discountsId);
      if (response) {
        setDetail(response)
        const taxBoard = response?.documents.find((val:any) =>  val.documentTypeId === '9d4e2f8a-909d-423a-8fbe-a4d05c8a5849' );
        const authorizedSignatures = response?.documents.find((val:any) => val.documentTypeId  === 'bd015910-d302-4a5e-b9e7-ffffe9db7e7e');
        const activityCertificate = response?.documents.find((val:any) => val.documentTypeId  === 'ca17b4f7-6602-4986-af0a-e8e1b47db843');
      
        setLegalDocs({
            taxBoard: taxBoard
                ? {
                      uid: taxBoard?.id,
                      documentType: taxBoard?.id,
                      name: taxBoard?.name,
                  }
                : undefined,
            authorizedSignatures: authorizedSignatures
                ? {
                      uid: authorizedSignatures?.id,
                      documentType: authorizedSignatures?.id,
                      name: authorizedSignatures?.name,
                  }
                : undefined,
            activityCertificate: activityCertificate
                ? {
                      uid: activityCertificate?.id,
                      documentType: activityCertificate?.id,
                      name: activityCertificate?.name,
                  }
                : undefined,
            // serviceAggrement: serviceAggrement
            //     ? {
            //           uid: serviceAggrement?.id,
            //           documentType: serviceAggrement?.id,
            //           name: serviceAggrement?.name,
            //       }
            //     : undefined,
        });      
    }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }

  const getDocTypeData = async () => {
    const response = await getDocType();
    if (response) {
        setDocType(response);
    }
};


  const handleDocumentUpload = async (file:any, key:any) => {
    setLegalDocs({ ...legalDocs, [key]: file });
    const formData = new FormData();
    formData.append('File', file);
    if( key === 'taxBoard') {
      const documentTypeId = docType && docType.documentTypes.find((doc:any) => doc.name === 'Vergi Levhası');
        if (documentTypeId) {
          await uploadDoc({
            formData,
            supplierId: detail?.supplier.id,
            documentTypeId: documentTypeId.id,
            supplierTitle:encodeURIComponent(detail?.supplier.title),
          });
          getSuppleirDetail()
        }
    }
    if(key === 'authorizedSignatures') {
      const documentTypeId = docType && docType.documentTypes.find((doc:any) => doc.name === 'İmza Sirküleri');
        if (documentTypeId) {
            await uploadDoc({
                formData,
                supplierId: detail?.supplier.id,
                documentTypeId: documentTypeId.id,
                supplierTitle:encodeURIComponent(detail?.supplier.title),
            });
            getSuppleirDetail()
        }
    }
    if(key === 'activityCertificate') {
        const documentTypeId = docType && docType.documentTypes.find((doc:any) => doc.name === 'Faaliyet Belgesi');
        if (documentTypeId) {
          await uploadDoc({
            formData,
            supplierId: detail?.supplier.id,
            documentTypeId: documentTypeId.id,
            supplierTitle:encodeURIComponent(detail?.supplier.title)
          });
          getSuppleirDetail()
        }
    }
    // if(key === 'serviceAggrement') {
    //     const documentTypeId = docType && docType.documentTypes.find((doc:any) => doc.name === 'İmzalı Hizmet Sözleşmesi');
    //     if (documentTypeId) {
    //       await uploadDoc({
    //         formData,
    //         supplierId: detail?.supplier.id,
    //         documentTypeId: documentTypeId.id,
    //         supplierTitle:encodeURIComponent(detail?.supplier.title)
    //       });
    //       getSuppleirDetail()
    //     }
    // }
    return false;
};

  const handleDeleteUpload = async (key:any) => {
    setLegalDocs({ ...legalDocs, [key]: undefined }); 
    const supplierId = detail?.supplier.id;

    if(key === 'taxBoard') {
        const documentTypeId = legalDocs.taxBoard;
        if (!documentTypeId.documentType) {
            notification.warning({
                message: 'Uyarı',
                description: (
                  <div className='common-err'>
                    Bir hata oluştu.Tekrar deneyiniz.
                  </div>
                ),
                duration: 2
              })
        }else{
            await deleteDocs(supplierId,documentTypeId.documentType);
        }
    }

    if(key === 'authorizedSignatures') {
        const documentTypeId = legalDocs.authorizedSignatures;
        if (!documentTypeId.documentType) {
            notification.warning({
                message: 'Uyarı',
                description: (
                  <div className='common-err'>
                    Bir hata oluştu.Tekrar deneyiniz.
                  </div>
                ),
                duration: 2
              })                
        }else{
            await deleteDocs(supplierId,documentTypeId.documentType);
        }
    }
    if(key === 'activityCertificate') {
        const documentTypeId = legalDocs.activityCertificate;
        if (!documentTypeId.documentType) {
            notification.warning({
                message: 'Uyarı',
                description: (
                  <div className='common-err'>
                    Bir hata oluştu.Tekrar deneyiniz.
                  </div>
                ),
                duration: 2
              })                
        }else{
            await deleteDocs(supplierId,documentTypeId.documentType);
        }
    }
    // if(key === 'serviceAggrement') {
    //     const documentTypeId = legalDocs.serviceAggrement;
    //     if (!documentTypeId.documentType) {
    //         notification.warning({
    //             message: 'Uyarı',
    //             description: (
    //               <div className='common-err'>
    //                 Bir hata oluştu.Tekrar deneyiniz.
    //               </div>
    //             ),
    //             duration: 2
    //           })                
    //     }else{
    //         await deleteDocs(supplierId,documentTypeId.documentType);
    //     }
    // }
    return true;
};
const downloadDoc = async (id:any, name:any) => {
  setLoading(id);
  const supplierId = detail.supplier.id
  const response = await getDocDownload(supplierId,id, name);
  if (response) {
      setLoading(false);
  }
}


// const handlePlatformDownload = async () => {
//   await LoadPdfPlatform(detail.supplier.title);
// };

useEffect(() => {
  getSuppleirDetail();
  getDocTypeData();
 // eslint-disable-next-line react-hooks/exhaustive-deps   
}, [])

  return (
    <main>
      <Row>
        <Col span={24}>
          <h3 className="page-title"> Detay</h3>
        </Col>
      </Row>
      <Row> 
        <Col span={7} style={{paddingRight:'40px', marginTop:'20px'}}>
          <ul className="left-item">
            <li>
              <span>Çiftçi VKN/TCKN</span>
              <span>{detail?.supplier?.taxId}</span>
            </li>
            <li>
              <span>Ünvan:</span>
              <span>{detail?.supplier?.title}</span>
            </li>
            <li>
              <span>Şirket Türü:</span>
              <span>{detail?.supplier?.companyType}</span>
            </li>
            <li>
              <span>İl:</span> 
              <span>
                {detail?.supplier?.province}
              </span>
            </li>
            <li>
              <span>Vergi Dairesi:</span>
              <span>{detail?.supplier?.taxAdministration}</span>
            </li>
            <li>
              <span>Ad Soyad:</span>
              <span>{detail?.supplier?.name} {detail?.supplier?.surname}</span>
            </li>
            <li>
              <span>E-posta Adresi:</span>
              <span>{detail?.supplier?.email}</span>
            </li>
            <li>
              <span>Cep Telefonu:</span>
              <span>{detail?.supplier?.phone}</span>
            </li>
          </ul>
        </Col>
    
        <Col span={17}>
                <div className="mb">
                    <div>Firmanıza ait gerekli belgeler ekleyin veya güncelleyin.</div>
                </div>
                <Form.Item name="taxBoard" style={{marginBottom:'10px'}}>
                    <div  style={{  marginTop: 10, fontWeight:'bold' }}>
                        Vergi Levhası
                    </div>
                    <div style={{ marginBottom:'5px'}}>
                        Şirkete ait vergi levhasını yükleyin.
                    </div>
                    {!legalDocs.taxBoard ? (
                        <Upload
                            className="legal-doc"
                            listType="picture-card"
                            accept="application/pdf"
                            multiple={false}
                            onRemove={() => handleDeleteUpload('taxBoard')}
                            beforeUpload={(file) => handleDocumentUpload(file, 'taxBoard')}
                            >
                            {!legalDocs.taxBoard && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <PlusOutlined />
                                    <div className="d-block" >
                                        Vergi Levhası
                                    </div>
                                </div>
                            )}
                        </Upload>
                    ) : 
                    !legalDocs.taxBoard.file && (
                        <div className="legal-doc-download">
                            <div role="button" tabIndex={-1} aria-hidden="true" 
                            onClick={() => downloadDoc(legalDocs.taxBoard.uid, legalDocs.taxBoard.name)}
                            >
                                <div style={{marginRight: 10}} className="d-block" >
                                    {loading === legalDocs.taxBoard.uid ? 
                                    <Spin indicator={antIcon} /> : <DownloadOutlined />}
                                    {legalDocs.taxBoard.name}
                                </div>
                            </div>
                            <CloseOutlined onClick={() => handleDeleteUpload('taxBoard')}/>
                        </div>
                    )
                    }
                </Form.Item>
                <Form.Item name="authorizedSignatures" style={{marginBottom:'10px'}}>
                    <div  style={{  fontWeight:'bold'  }}>
                        İmza Sirküleri
                    </div>
                    <div  style={{ marginBottom:'5px' }}>
                        Güncel imza sirkülerini yükleyin.
                    </div>
                    {!legalDocs.authorizedSignatures ? (
                        <Upload
                            className="legal-doc"
                            listType="picture-card"
                            accept="application/pdf" 
                            multiple={false}
                            onRemove={() => handleDeleteUpload('authorizedSignatures')}
                            beforeUpload={(file) => handleDocumentUpload(file, 'authorizedSignatures')}>
                            {!legalDocs.authorizedSignatures && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                   <PlusOutlined />
                                    <div className="d-block" >
                                        İmza Sirküleri
                                    </div>
                                </div>
                            )}
                        </Upload>
                    ) : !legalDocs.authorizedSignatures.file && (
                        <div className="legal-doc-download">
                            <div role="button" tabIndex={-1} aria-hidden="true" 
                             onClick={() => downloadDoc(legalDocs.authorizedSignatures.uid, legalDocs.authorizedSignatures.name)}
                            >
                                <div style={{marginRight: 10}} className="d-block" >
                                    {loading === legalDocs.authorizedSignatures.uid ? 
                                    <Spin indicator={antIcon} /> : <DownloadOutlined />}
                                    {legalDocs.authorizedSignatures.name}
                                </div>
                            </div>
                            <CloseOutlined onClick={() => handleDeleteUpload('authorizedSignatures')}/>
                        </div>
                    )}
                </Form.Item>
                <Form.Item name="activityCertificate" style={{marginBottom:'10px'}}>
                    <div  style={{  fontWeight:'bold'  }}>
                        Faaliyet Belgesi
                    </div>
                    <div  style={{ marginBottom:'5px'}}>
                        Bağlı olduğunuz ticaret odasından temin ettiğiniz faaliyet belgenizi yükleyin.
                    </div>
                    {!legalDocs.activityCertificate ? (
                        <Upload
                            className="legal-doc"
                            listType="picture-card"
                            accept="application/pdf"
                            multiple={false}
                            onRemove={() => handleDeleteUpload('activityCertificate')}
                            beforeUpload={(file) => handleDocumentUpload(file, 'activityCertificate')}>
                            {!legalDocs.activityCertificate && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <PlusOutlined />
                                    <div className="d-block" >
                                        Faaliyet Belgesi
                                    </div>
                                </div>
                            )}
                        </Upload>
                    ) : !legalDocs.activityCertificate.file && (
                        <div className="legal-doc-download">
                            <div role="button" tabIndex={-1} aria-hidden="true" 
                            onClick={() => downloadDoc(legalDocs.activityCertificate.uid, legalDocs.activityCertificate.name)}
                            >
                                <div style={{marginRight: 10}} className="d-block" >
                                    {loading === legalDocs.activityCertificate.uid ? 
                                    <Spin indicator={antIcon} /> : <DownloadOutlined />}
                                    {legalDocs.activityCertificate.name}
                                </div>
                            </div>
                            <CloseOutlined onClick={() => handleDeleteUpload('activityCertificate')}/>
                        </div>
                    )}
                </Form.Item>
                {/* <Form.Item name="serviceAggrement" style={{marginBottom:'10px'}}>
                    <div  style={{fontWeight:'bold' }}>
                        İmzalı Hizmet Sözleşmesi
                    </div>
                    <div  style={{ marginBottom:'5px' }}>
                        Hizmet sözleşmemizi indirin, okuyup imzaladıktan sonra bir kopyayı buraya yükleyin.
                    </div>
                        <div style={{display:'flex'}}>
                        <div>
                            <Button style={{ height:'44px', width:'200px'}}
                              onClick={handlePlatformDownload} 
                              type="default" className="download-btn">
                                <VerticalAlignBottomOutlined
                                    style={{ fontSize: 20, verticalAlign: 'text-top' }}
                                />
                                Sözleşmeyi İndir
                            </Button>
                        </div>
                        <div style={{marginLeft:'15px'}}>
                        {!legalDocs.serviceAggrement ? (
                            <Upload
                                className="legal-doc"
                                listType="picture-card"
                                accept="application/pdf"
                                multiple={false}
                                onRemove={() => handleDeleteUpload('serviceAggrement')}
                                beforeUpload={(file) => handleDocumentUpload(file, 'serviceAggrement')}>
                                {!legalDocs.serviceAggrement && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                       <PlusOutlined />
                                        <div className="d-block" >
                                            Yükle
                                        </div>
                                    </div>
                                )}
                            </Upload>
                        ) : 
                        !legalDocs.serviceAggrement.file && (
                            <div className="legal-doc-download">
                                <div role="button" tabIndex={-1} aria-hidden="true" 
                                 onClick={() => downloadDoc(legalDocs.serviceAggrement.uid, legalDocs.serviceAggrement.name)}
                                >
                                    <div style={{marginRight: 10}} className="d-block" >
                                        {loading === legalDocs.serviceAggrement.uid ? 
                                        <Spin indicator={antIcon} /> : <DownloadOutlined />}
                                        {legalDocs.serviceAggrement.name}
                                    </div>
                                </div>
                                <CloseOutlined onClick={() => handleDeleteUpload('serviceAggrement')}/>
                            </div>
                        )
                        }
                        </div>

                        </div>
                </Form.Item> */}
            </Col>
      </Row>
    
    </main>
  );
};

export default AccountTransactionsDetail;
