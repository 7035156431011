import React, { useState, useEffect } from "react";
import { Row,  Col, Table , Button, Input, Form} from "antd";
import moment from 'moment';
import { getDiscountsApi } from "../../services/supplierApi";
import { convertFloatDotSeperated } from "../../helpers/common";
import { useNavigate } from "react-router-dom";
import {applicationStatusMapByValue} from "../../helpers/common";


function TransactionsHistory() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [discounts, setDiscounts] = useState<any>();
  const [psize, setPsize] = useState<any>(10);
  const [page, setpage] = useState<any>(1);
  const [totalDataCount, setTotalDataCount] = useState<any>(1);
  const [searchQuery, setSearchQuery] = useState('');

  const [filterForm] = Form.useForm();

  const columns: any = [
    {
      title: "Başvuru No",
      dataIndex: "applicationNumber",
      key: "applicationNumber",
      fixed: 'left',
      width:90
    },
    {
      title: "İşlem Tarihi",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date:any) => moment(date).format('DD-MM-YYYY'),
      width:120
    },
    {
      title: "Çiftçi VKN/TCKN",
      dataIndex: "supplierTaxNumber",
      key: "supplierTaxNumber",
      width:140,
    },
    {
      title: "Çiftçi",
      dataIndex: "supplierTitle",
      key: "supplierTitle",
      width:200
    },
    {
      title: "Alıcı VKN/TCKN",
      dataIndex: "buyerTaxNumber",
      key: "buyerTaxNumber",
      width:140
    },
    {
      title: "Banka",
      dataIndex: "financialInstitutionName",
      key: "financialInstitutionName",
      width:160
    },
    {
      title: "Toplam Fatura Tutarı",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
      render: convertFloatDotSeperated,
      width:120
    },
    {
      title: "Toplam Fatura Adedi",
      dataIndex: "invoiceCount",
      key: "invoiceCount",
      width:100
    },
    {
      title: "Ortalama Vade",
      dataIndex: "averageMaturity",
      key: "averageMaturity",
      width:100
    },
    {
      title: "Durum",
      dataIndex: "status",
      key: "status",
      width:140,
      render: (value:any) => <span style={{color:applicationStatusMapByValue[value].color}}>{applicationStatusMapByValue[value].text}</span>
    },
    {
      title:'',
      dataIndex:'detail',
      key:'detail',     
      render: (text: any, row: any) =>  
        <Button 
          type="primary" 
          onClick={()  => navigate(`/farmers-transactions-history-detail/${row?.id}`)}
          > Detay
        </Button>         
  },
    
  ];


  const getDiscounts  = async () => {  
    try {
      setLoading(true);
      const response: any = await getDiscountsApi(psize,page,searchQuery);
      if (response) {
        setLoading(false);
        setDiscounts(response.discounts.data);
        setTotalDataCount(response.discounts.totalDataCount);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }

  const onchangeValue = (e:any) => {
    setSearchQuery(e.title === undefined ? '': e.title)
  }

  const clearValue = () => {
    setSearchQuery('');
    filterForm.resetFields()
  }


  const handleShowSize = (current: any, size: any) => {
    setpage(current);
    setPsize(size);
  };

  useEffect(() => {
    getDiscounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, [page,psize,searchQuery])

  return (
    <main>
      <Row style={{marginBottom:'20px'}}>
        <Col span={24}>
          <h3 className="page-title"> İşlem Geçmişi</h3>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom:'10px'}}>
          <Form
            form={filterForm}
            name="filter-box"
            onFinish={onchangeValue}
            autoComplete="off"
            labelCol={{
              span: 8,
            }}
            size = "large"
            style={{
              minWidth: 400,
            }}
            >
            <div className='filter-row'>                
              <div>
                <Form.Item name="title" style={{marginBottom:'0'}} >
                  <Input 
                      placeholder="Banka veya Çiftçi Ünvanı Giriniz"
                      style={{minWidth:'280px'}}
                     />
                </Form.Item>
              </div>               
              <div>
                <Form.Item style={{marginBottom:'0'}}>
                  <Button
                      type="primary"
                      htmlType="submit"                 
                  >Filtrele</Button>
                </Form.Item>
              </div>
              <div>
                <Form.Item style={{marginBottom:'0'}}>
                  <Button
                      type="primary"
                      onClick={() =>  clearValue()}               
                  >Temizle</Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Col>
      </Row> 
      <Row>
      <Col span={24} style={{ margin: "0px auto" }}>
          <Table
            className="w-100 invoiceTable"
            pagination={{
              position: ["topRight", "none" as any],
              showLessItems: true,                          
              showSizeChanger: true,
              current: page,
              pageSize:psize,
              total:totalDataCount, 
              onChange: handleShowSize,
              
            }}
            loading={loading} 
            dataSource={discounts}
            columns={columns}
            scroll={{
              x: 1200,
            }}
          />
        </Col>
      </Row>
    </main>
  );
};

export default TransactionsHistory;
